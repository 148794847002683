import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/home/runner/work/nulogy.design/nulogy.design/src/components/MarkdownRenderer.js";
import { Box, Flex, Heading1, Heading2, Heading3, Heading4 } from "@nulogy/components";
import { COPY_WIDTH } from "../../components/CONSTANTS";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const Text = makeShortcode("Text");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h1>{`Typography`}</h1>
    <p>{`Nulogy applications are data heavy and used in various environments. Sticking to a typographic scale makes our applications more predictable and scannable for our users. Below are all the sizes and line heights available to use for Nulogy applications.`}</p>
    <h2>{`Typeface`}</h2>
    <p>{`Our primary typeface is IBM Plex Sans. This is used for all headings and body copy. We also have a monospace version available for lining up numbers.`}</p>
    <Flex maxWidth={COPY_WIDTH} mx="auto" flexDirection={{
      extraSmall: "column",
      small: "row"
    }} mdxType="Flex">
  <Box width={{
        extraSmall: 1,
        medium: 1 / 2
      }} mb={{
        extraSmall: "x3",
        small: 0
      }} mdxType="Box">
    <Heading3 mdxType="Heading3">IBM Plex Sans</Heading3>
    <a href="https://www.ibm.com/plex/">https://www.ibm.com/plex/</a>
  </Box>
  <Box width={{
        extraSmall: 1,
        medium: 1 / 2
      }} mdxType="Box">
    <Heading3 fontFamily="mono" mdxType="Heading3">IBM Plex Mono</Heading3>
    <a href="https://www.ibm.com/plex/">https://www.ibm.com/plex/</a>
  </Box>
    </Flex>
    <h2>{`Headings`}</h2>
    <Box maxWidth={COPY_WIDTH} mx="auto" mdxType="Box">
  <Box mb="x4" mdxType="Box">
    <Text color="darkGrey" fontSize="small" mb="half" mdxType="Text">
      Heading 1 (38px/40px)
    </Text>
    <Heading1 mdxType="Heading1">The quick brown fox jumps over the lazy dog</Heading1>
  </Box>
  <Box mb="x4" mdxType="Box">
    <Text color="darkGrey" fontSize="small" mb="half" mdxType="Text">
      Heading 2 (30px/40px)
    </Text>
    <Heading2 mdxType="Heading2">The quick brown fox jumps over the lazy dog</Heading2>
  </Box>
  <Box mb="x4" mdxType="Box">
    <Text color="darkGrey" fontSize="small" mb="half" mdxType="Text">
      Heading 3 (24px/32px)
    </Text>
    <Heading3 mdxType="Heading3">The quick brown fox jumps over the lazy dog</Heading3>
  </Box>
  <Box mb="x4" mdxType="Box">
    <Text color="darkGrey" fontSize="small" mb="half" mdxType="Text">
      Heading 4 (18px/24px)
    </Text>
    <Heading4 mdxType="Heading4">The quick brown fox jumps over the lazy dog</Heading4>
  </Box>
    </Box>
    <h2>{`Interface text`}</h2>
    <p>{`Standard text should set at 16px for most cases, but 14px or 12px is also available for special cases.`}</p>
    <h2>{`Usage`}</h2>
    <p>{`Plex can be loaded in your application through whichever method you prefer to load fonts. An example of loading through Google fonts is shown below.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-jsx"
      }}>{`<link href="https://fonts.googleapis.com/css?family=IBM+Plex+Sans:300,400,500,600" rel="stylesheet" />
<link href="https://fonts.googleapis.com/css?family=IBM+Plex+Mono" rel="stylesheet" />
`}</code></pre>
    <p><em parentName="p">{`Note that if your application supports Simplified Chinese, you'll also need to load the `}<a parentName="em" {...{
          "href": "https://www.google.com/get/noto/"
        }}>{`Noto Sans SC`}</a>{` font. For more information, see the `}<a parentName="em" {...{
          "href": "https://github.com/nulogy/design-system/blob/master/components/README.md#2-add-fonts"
        }}>{`README`}</a>{`.`}</em></p>
    <h2>{`Related links`}</h2>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/theme"
        }}>{`NDS theme`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/components/text"
        }}>{`Text`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/components/headings"
        }}>{`Headings`}</a></li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      